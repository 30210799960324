import {FC, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {Error404} from '../errors/Error404'
import {setLanguageTemp} from '../../../_metronic/i18n/Metronici18n'
import {setTempStorage} from '../../helpers/StorageHelper'
import ReporteTablaEstimacionesMensuales from '../../pages/pdf/ReporteTablaEstimacionesMensuales'
import ReporteUProcedimientoMedida from '../../pages/pdf/ReporteUProcedimientoMedida'
import ReporteUEquipo from '../../pages/pdf/ReporteUEquipo'
import ReporteCEICTradicionalProcedimientoMedida from '../../pages/pdf/ReporteCEICTradicionalProcedimientoMedida'
import ReporteCEICTradicionalEquipo from '../../pages/pdf/ReporteCEICTradicionalEquipo'
import ReporteCEICContinuoProcedimientoMedida from '../../pages/pdf/ReporteCEICContinuoProcedimientoMedida'
import ReporteCEICContinuoEquipo from '../../pages/pdf/ReporteCEICContinuoEquipo'

const GetExternalComponent: FC = () => {
  const search = useLocation().search
  const params = new URLSearchParams(search)

  const action = params.get('action')
  const accessToken = params.get('token')
  const lang = params.get('lang')

  useEffect(() => {
    if (!lang || !accessToken) return
    setLanguageTemp(lang)
    setTempStorage(accessToken)
  }, [])

  if (!accessToken || !lang) return <Error404 />

  switch (action) {
    case 'REPORTE_PDF':
      const type = params.get('type')

      switch (type) {
        case 'ESTIMACIONES_MENSUALES':
          return <ReporteTablaEstimacionesMensuales />
        case 'U_PROCEDIMIENTO_MEDIDA':
          return <ReporteUProcedimientoMedida />
        case 'U_EQUIPO':
          return <ReporteUEquipo />
        case 'CEIC_TRADICIONAL_PROCEDIMIENTO_MEDIDA':
          return <ReporteCEICTradicionalProcedimientoMedida />
        case 'CEIC_TRADICIONAL_EQUIPO':
          return <ReporteCEICTradicionalEquipo />
        case 'CEIC_CONTINUO_PROCEDIMIENTO_MEDIDA':
          return <ReporteCEICContinuoProcedimientoMedida />
        case 'CEIC_CONTINUO_EQUIPO':
          return <ReporteCEICContinuoEquipo />
        default:
          return <Error404 />
      }

    default:
      return <Error404 />
  }
}

export default GetExternalComponent
