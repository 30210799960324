import {FC, Fragment} from 'react'
import {CEICTradicionalProcedimientoMedidaDTO} from '../../models/DTOs/SCI/reportes/CEICTradicionalProcedimientoMedidaDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import GraficoCEICTradicionalProcedimientoMedida from '../../components/reportes/ceic-tradicional-procedimiento-medida/GraficoCEICTradicionalProcedimientoMedida'

const ReporteCEICTradicionalProcedimientoMedida: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: CEICTradicionalProcedimientoMedidaDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <div className='table-responsible mb-10'>
          <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
            <thead
              style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}
            >
              <tr className='fw-bolder'>
                <th style={{width: `10%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'HEADER.PERIOD'})}
                </th>
                <th style={{width: '10%', textAlign: 'center'}}>
                  {intl.formatMessage({id: 'HEADER.LEVEL'})}
                </th>
                <th style={{width: `10%`, textAlign: 'center'}}>Sigma</th>
                <th style={{width: `10%`, textAlign: 'center'}}>ESc</th>
                <th style={{width: `20%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'MENU.CONTROL_RULES'})}
                </th>
                <th style={{width: `10%`, textAlign: 'center'}}>N</th>
                <th style={{width: `10%`, textAlign: 'center'}}>R</th>
                <th style={{width: `10%`, textAlign: 'center'}}>Ped (%)</th>
                <th style={{width: `10%`, textAlign: 'center'}}>Pfr (%)</th>
              </tr>
            </thead>
            <tbody>
              {reporte.Periodos?.length > 0 ? (
                reporte.Periodos.map((periodo) => {
                  return (
                    <tr
                      key={`reporte_ceic_tradicional_proc_medida_periodo_${periodo.Periodo}`}
                      style={{textAlign: 'right'}}
                    >
                      <td style={{textAlign: 'center'}}>{periodo.Periodo}</td>
                      <td style={{textAlign: 'center'}}>{periodo.Nivel}</td>
                      <td>{periodo.Sigma.Value.toFixed(periodo.Sigma.Precision)}</td>
                      <td>{periodo.ESc.Value.toFixed(periodo.ESc.Precision)}</td>
                      {periodo.EsquemaReglas ? (
                        <Fragment>
                          <td style={{textAlign: 'center'}}>
                            {periodo.EsquemaReglas.ReglasControl.map((regla) => {
                              return (
                                <span
                                  key={`reporte_ceic_tradicional_proc_medida_periodo_${periodo.Periodo}_regla_${regla.Id}`}
                                  className={`badge badge-light-dark m-1`}
                                >
                                  {regla.Name}
                                </span>
                              )
                            })}
                          </td>
                          <td>{periodo.EsquemaReglas.N}</td>
                          <td>{periodo.EsquemaReglas.R}</td>
                          <td style={{textAlign: 'center'}}>{periodo.EsquemaReglas.Ped}</td>
                          <td>{periodo.EsquemaReglas.Pfr}</td>
                        </Fragment>
                      ) : (
                        <td colSpan={5} style={{textAlign: 'center'}}>
                          {intl.formatMessage({id: 'NOT_PLANNABLE'})}
                        </td>
                      )}
                    </tr>
                  )
                })
              ) : (
                <tr className='text-center mb-5'>
                  <td colSpan={20}>{intl.formatMessage({id: 'NOT_FOUND.RESULTS'})}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {reporte.Periodos?.length > 0 && (
          <GraficoCEICTradicionalProcedimientoMedida reporte={reporte} />
        )}
      </div>
    </>
  )
}

export default ReporteCEICTradicionalProcedimientoMedida
