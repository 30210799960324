import {FC, useEffect, useState} from 'react'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
import {CEICTradicionalEquipoDTO} from '../../../models/DTOs/SCI/reportes/CEICTradicionalEquipoDTO'
more(Highcharts)

interface Props {
  reporte: CEICTradicionalEquipoDTO
}

const GraficoSigmaCEICTradicionalEquipo: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  const GetPieSerie = (data: any): Highcharts.SeriesOptionsType => {
    const _data = data.map((v: any) => {
      return {
        name: v.Name,
        y: v.ValuePercent,
        color: v.Color,
        x: v.Value,
      }
    })

    const result: Highcharts.SeriesOptionsType = {
      type: 'pie',
      data: _data,
    }

    return result
  }

  const buildDataItem = (name: string, value: any, color: string) => {
    return {
      Name: name,
      Value: value.TotalItems,
      ValuePercent: +value.TotalItemsPercent.Value.toFixed(value.TotalItemsPercent.Precision),
      Color: color,
    }
  }

  useEffect(() => {
    const data: any[] = []
    data.push(buildDataItem('Sigma < 3', reporte.SigmasSummary.LowerThan3, '#F00'))
    data.push(buildDataItem('Sigma 3-4', reporte.SigmasSummary.Betwen3And4, '#effa39'))
    data.push(buildDataItem('Sigma 4-5', reporte.SigmasSummary.Betwen4And5, '#53ffff'))
    data.push(buildDataItem('Sigma 5-6', reporte.SigmasSummary.Betwen5And6, '#33eb33'))
    data.push(buildDataItem('Sigma > 6', reporte.SigmasSummary.HigherThan6, '#4ea44e'))

    const series: Highcharts.SeriesOptionsType[] = [GetPieSerie(data)]

    setOptions({
      title: {
        text: 'Sigma procedimientos de medida',
        x: -20,
      },
      credits: {
        enabled: false,
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderWidth: 0,
        itemStyle: {
          fontSize: '14',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size: 14">{series.name}</span><br/>',
        pointFormat: '<b style="font-size: 14">{point.y} % ({point.x})</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
        series: {
          animation: false,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
              select: {
                enabled: false,
              },
            },
          },
        },
      },
      series: series,
    })
  }, [reporte])

  return (
    <div className='d-flex justify-content-center'>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default GraficoSigmaCEICTradicionalEquipo
