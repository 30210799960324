import React, {Suspense, lazy} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import {UserModel} from '../models/UserModel'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {RolUsuario} from '../models/DTOs/common/types'
import {Logout} from '../pages/auth'

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const AccountPage = lazy(() => import('../pages/account/AccountPage'))
  const Tickets = lazy(() => import('../pages/tickets/Tickets'))
  const DatosMensuales = lazy(() => import('../pages/datos-mensuales/DatosMensuales'))
  const EstimacionesMensuales = lazy(
    () => import('../pages/reportes/estimaciones-mensuales/EstimacionesMensuales')
  )
  const UProcedimientoMedida = lazy(
    () => import('../pages/reportes/u-por-procedimiento-medida/UProcedimientoMedida')
  )
  const UEquipo = lazy(() => import('../pages/reportes/u-por-equipo/UEquipo'))
  const CEICTradicionalProcedimientoMedida = lazy(
    () =>
      import(
        '../pages/reportes/ceic-tradicional-procedimiento-medida/CEICTradicionalProcedimientoMedida'
      )
  )
  const CEICTradicionalEquipo = lazy(
    () => import('../pages/reportes/ceic-tradicional-equipo/CEICTradicionalEquipo')
  )
  const CEICContinuoProcedimientoMedida = lazy(
    () =>
      import('../pages/reportes/ceic-continuo-procedimiento_medida/CEICContinuoProcedimientoMedida')
  )
  const CEICContinuoEquipo = lazy(
    () => import('../pages/reportes/ceic-continuo-equipo/CEICContinuoEquipo')
  )

  // const isAdmin = user.Rol.Id === RolUsuario.AdministradorLaboratorio

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/account' component={AccountPage} />
        <Route path='/support/tickets' component={Tickets} />
        <Route path='/monthly-data' component={DatosMensuales} />
        <Route path='/reports/monthly-estimates' exact component={EstimacionesMensuales} />
        <Route path='/reports/u-measurement-procedure' exact component={UProcedimientoMedida} />
        <Route path='/reports/u-equipment' exact component={UEquipo} />
        <Route
          path='/reports/ceic-traditional-measurement-procedure'
          exact
          component={CEICTradicionalProcedimientoMedida}
        />
        <Route path='/reports/ceic-traditional-equipment' exact component={CEICTradicionalEquipo} />
        <Route
          path='/reports/ceic-continuous-measurement-procedure'
          exact
          component={CEICContinuoProcedimientoMedida}
        />
        <Route path='/reports/ceic-continuous-equipment' exact component={CEICContinuoEquipo} />
        <Route path='/logout' component={Logout} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
