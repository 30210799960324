import {FC, useEffect, useState} from 'react'
import {CEICTradicionalProcedimientoMedidaDTO} from '../../../models/DTOs/SCI/reportes/CEICTradicionalProcedimientoMedidaDTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
more(Highcharts)

interface Props {
  reporte: CEICTradicionalProcedimientoMedidaDTO
}

const GraficoCEICTradicionalProcedimientoMedida: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>({})
  const intl = useIntl()

  const GetChartNivelValue = (nivel: any) => {
    const sigmaValue = +nivel.Sigma?.Value.toFixed(nivel.Sigma?.Precision) ?? 0

    return {
      y: sigmaValue >= 0 ? sigmaValue : 0,
      Sigma: sigmaValue,
      SesgoPercent: +nivel.SesgoPercent?.Value.toFixed(nivel.SesgoPercent?.Precision) ?? 0,
      CvMensual: +nivel.Laboratorio.Cv?.Value.toFixed(nivel.Laboratorio.Cv?.Precision) ?? 0,
    }
  }

  const GetChartPeriodoNivelSerie = (
    periodos: any,
    nivel: number
  ): Highcharts.SeriesOptionsType => {
    const values = periodos.map((p: any) => GetChartNivelValue(p.Niveles[nivel]))

    return {
      name: `${intl.formatMessage({id: 'HEADER.LEVEL'})} ${nivel + 1}`,
      type: 'column',
      data: values,
      showInLegend: true,
      dataLabels: {
        color: '#FFF',
        backgroundColor: '#000',
        borderRadius: 5,
        style: {
          fontSize: '12px',
        },
        format: '{point.Sigma}',
      },
    }
  }

  useEffect(() => {
    const cantNiveles = reporte.Periodos[0].Niveles.length
    const xAxisCategories = reporte.Periodos.map((p) => p.Periodo).flat()
    const nivelesSeries: Highcharts.SeriesOptionsType[] = []
    for (let i = 0; i < cantNiveles; i++)
      nivelesSeries.push(GetChartPeriodoNivelSerie(reporte.Periodos, i))

    let yMax = 1
    reporte.Periodos.forEach((periodo) => {
      if (periodo.Sigma.Value > yMax) yMax = periodo.Sigma.Value
    })

    setOptions({
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: xAxisCategories,
        title: {
          text: intl.formatMessage({id: 'HEADER.PERIOD'}),
        },
      },
      yAxis: {
        max: yMax * 1.1,
        min: 0,
        title: {
          text: 'Sigma',
        },
        gridLineWidth: 1,
        endOnTick: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat: `Sigma: {point.Sigma}</br>Sesgo (%): {point.SesgoPercent}</br>CV mensual: {point.CvMensual}`,
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
              select: {
                enabled: false,
              },
            },
          },
        },
        column: {
          dataLabels: {
            verticalAlign: 'top',
            allowOverlap: true,
            enabled: true,
            inside: true,
          },
          pointWidth: 10,
          maxPointWidth: 40,
          pointPadding: 1,
          grouping: true,
        },
      },
      series: nivelesSeries,
    })
  }, [reporte])

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default GraficoCEICTradicionalProcedimientoMedida
