import {FC, useEffect, useState} from 'react'
import {UProcedimientoMedidaDTO} from '../../../models/DTOs/SCI/reportes/UProcedimientoMedidaDTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
import {ValueWithPrecisionDTO} from '../../../models/DTOs/common/ValueWithPrecisionDTO'
more(Highcharts)

interface Props {
  reporte: UProcedimientoMedidaDTO
}

const GraficoIncertidumbreMedida: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>({})
  const intl = useIntl()

  const GetChartColumnValue = (n: ValueWithPrecisionDTO, isAceptado: boolean) => {
    const value = +n?.Value.toFixed(n?.Precision) || 0

    return {
      y: value,
      color: isAceptado ? '#0F0' : '#F00',
    }
  }

  useEffect(() => {
    const rPeriodos = [...reporte.Periodos].reverse()

    const xAxisCategories = rPeriodos.flatMap((p) =>
      p.Niveles.map(
        (n) => `${intl.formatMessage({id: 'HEADER.LEVEL'})} ${n.Numero}<br/>(${p.Periodo})`
      )
    )
    const uValues = rPeriodos.flatMap((p) =>
      p.Niveles.map((n) => GetChartColumnValue(n.UPercent, n.IsAceptado))
    )
    const uObjValues = rPeriodos.flatMap((p) =>
      p.Niveles.map((n) => [0, +n.UObjPercent.Value.toFixed(n.UObjPercent.Precision)])
    )

    setOptions({
      title: {
        text: intl.formatMessage({id: 'HEADER.MEASUREMENT_UNCERTAINTY'}),
      },
      credits: {
        enabled: false,
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderWidth: 0,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
              select: {
                enabled: false,
              },
            },
          },
        },
      },
      yAxis: {
        title: {
          text: 'U (%)',
        },
        min: 0,
        gridLineWidth: 1,
      },
      xAxis: {
        type: 'category',
        title: {
          text: intl.formatMessage({id: 'HEADER.LEVEL'}),
        },
        categories: xAxisCategories,
      },
      series: [
        {
          name: 'U (%)',
          type: 'column',
          data: uValues,
          color: '#252525',
          showInLegend: false,
        },
        {
          name: 'U<sup>obj (%)',
          type: 'errorbar',
          data: uObjValues,
          color: '#00F',
          showInLegend: true,
        },
      ],
    })
  }, [])

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default GraficoIncertidumbreMedida
