import {FC, Fragment} from 'react'
import {UProcedimientoMedidaDTO} from '../../models/DTOs/SCI/reportes/UProcedimientoMedidaDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import GraficoIncertidumbreMedida from '../../components/reportes/u-procedimiento-medida/GraficoIncertidumbreMedida'

const ReporteUProcedimientoMedida: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: UProcedimientoMedidaDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <div className='table-responsible'>
          <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
            <thead
              style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}
            >
              <tr className='fw-bolder'>
                <th style={{width: `15%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'HEADER.PERIOD'})}
                </th>
                <th style={{width: '10%', textAlign: 'center'}}>
                  {intl.formatMessage({id: 'HEADER.LEVEL'})}
                </th>
                <th style={{width: `15%`, textAlign: 'center'}}>U %</th>
                <th style={{width: `15%`, textAlign: 'center'}}>U cc</th>
                <th style={{width: `15%`, textAlign: 'center'}}>NDM</th>
                <th style={{width: `15%`, textAlign: 'center'}}>
                  U<sup>obj</sup>
                </th>
                <th style={{width: `15%`, textAlign: 'center'}}>
                  U<sup>obj</sup> %
                </th>
              </tr>
            </thead>
            <tbody>
              {reporte.Periodos?.length > 0 ? (
                reporte.Periodos.map((periodo) => {
                  return (
                    <tr
                      key={`reporte_u_procedimiento_medida_periodo_${periodo.Periodo}`}
                      style={{textAlign: 'right'}}
                    >
                      <td rowSpan={periodo.Niveles.length} style={{textAlign: 'center'}}>
                        {periodo.Periodo}
                      </td>
                      {periodo.Niveles.map((nivel) => {
                        return (
                          <Fragment
                            key={`reporte_u_procedimiento_medida_periodo_${periodo.Periodo}_nivel_${nivel.Numero}`}
                          >
                            <td style={{textAlign: 'center'}}>{nivel.Numero}</td>
                            <td>
                              {nivel.UPercent?.Value.toFixed(nivel.UPercent?.Precision) ?? '-'}
                            </td>
                            <td>{nivel.UCc?.Value.toFixed(nivel.UCc?.Precision) ?? '-'}</td>
                            <td>{nivel.NDM?.Value.toFixed(nivel.NDM?.Precision) ?? '-'}</td>
                            <td>{nivel.UObjCc?.Value.toFixed(nivel.UObjCc?.Precision) ?? '-'}</td>
                            <td>
                              {nivel.UObjPercent?.Value.toFixed(nivel.UObjPercent?.Precision) ??
                                '-'}
                            </td>
                          </Fragment>
                        )
                      })}
                    </tr>
                  )
                })
              ) : (
                <tr className='text-center mb-5'>
                  <td colSpan={20}>{intl.formatMessage({id: 'NOT_FOUND.RESULTS'})}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {reporte.Periodos?.length > 0 && <GraficoIncertidumbreMedida reporte={reporte} />}
      </div>
    </>
  )
}

export default ReporteUProcedimientoMedida
