import {FC, useEffect, useState} from 'react'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useIntl} from 'react-intl'
import {CEICContinuoEquipoDTO} from '../../../models/DTOs/SCI/reportes/CEICContinuoEquipoDTO'
more(Highcharts)

interface Props {
  reporte: CEICContinuoEquipoDTO
}

const GraficoErrorAleatorioCEICContinuoEquipo: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const intl = useIntl()

  const getAreaSerie = (
    name: string,
    x: number,
    y: number,
    color: string,
    fillOpacity = 0.7
  ): Highcharts.SeriesOptionsType => {
    return {
      type: 'area',
      name: name,
      fillOpacity: fillOpacity,
      color: color,
      data: [
        [0, y],
        [x, 0],
      ],
      marker: {
        enabled: false,
      },
      states: {
        hover: {
          enabled: false,
        },
        select: {
          enabled: false,
        },
        inactive: {
          opacity: fillOpacity,
        },
      },
    }
  }

  const textToImageURL = (text: string, fontSize: number) => {
    const width = text.length * 10
    const height = fontSize - 6

    const canvas = document.createElement('canvas')
    canvas.style.width = `${width}px`
    canvas.style.height = `${height}px`
    canvas.width = width
    canvas.height = height

    const ctx = canvas.getContext('2d')
    ctx!.font = `${fontSize}px serif`
    ctx!.fillText(text, 0, height)

    return canvas.toDataURL()
  }

  const buildScatterSerie = (item: any, index: number): Highcharts.SeriesOptionsType => {
    const x =
      +item.Nivel.ErrorSistematicoPercent.Value <= 60
        ? +item.Nivel.ErrorSistematicoPercent.Value.toFixed(
            item.Nivel.ErrorSistematicoPercent.Precision
          )
        : 60

    const y =
      +item.Nivel.ErrorAleatorioPercent.Value <= 100
        ? +item.Nivel.ErrorAleatorioPercent.Value.toFixed(
            item.Nivel.ErrorAleatorioPercent.Precision
          )
        : 100

    const data = [[x, y]]

    return {
      type: 'scatter',
      data: data,
      marker: {
        enabled: true,
        symbol: `url(${textToImageURL(index.toString(), 20)})`,
      },
    }
  }

  useEffect(() => {
    const series: Highcharts.SeriesOptionsType[] = []
    series.push(getAreaSerie('Inaceptable', 20000, 100, '#F00'))
    series.push(getAreaSerie('Marginal', 100 / 3, 100, '#effa39'))
    series.push(getAreaSerie('Razonable', 100 / 4, 100, '#53ffff'))
    series.push(getAreaSerie('Bueno', 100 / 5, 100, '#33eb33'))
    series.push(getAreaSerie('Six Sigma', 100 / 6, 100, '#4ea44e'))

    for (const index in reporte.ProcedimientosMedida) {
      series.push(buildScatterSerie(reporte.ProcedimientosMedida[index], +index + 1))
    }

    setOptions({
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        min: 0,
        max: 60,
        title: {
          text: 'Error sistemático (ETa %)',
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: 'Error aleatorio (ETa %)',
        },
        gridLineWidth: 0,
      },
      plotOptions: {
        series: {
          animation: false,
          enableMouseTracking: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      series: series,
    })
  }, [reporte])

  return (
    <div>
      <div className='d-flex justify-content-center text-secondary'>
        <span className={`badge mx-3`} style={{backgroundColor: '#4db953', color: '#f9fcf9'}}>
          SIX SIGMA
        </span>
        <span className={`badge mx-3`} style={{backgroundColor: '#4bec5f', color: '#f9fcf9'}}>
          BUENO
        </span>
        <span className={`badge mx-3`} style={{backgroundColor: '#82eec6', color: '#f9fcf9'}}>
          RAZONABLE
        </span>
        <span className={`badge mx-3`} style={{backgroundColor: '#f3c63f', color: '#f9fcf9'}}>
          MARGINAL
        </span>
        <span className={`badge mx-3`} style={{backgroundColor: '#ff4c4c', color: '#f9fcf9'}}>
          INACEPTABLE
        </span>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default GraficoErrorAleatorioCEICContinuoEquipo
