import {FC, Fragment} from 'react'
import {CEICContinuoEquipoDTO} from '../../models/DTOs/SCI/reportes/CEICContinuoEquipoDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import {arrow} from '@popperjs/core'
import {AlertSuccess} from '../../components/UI/alerts/Alerts'
import GraficoErrorAleatorioCEICContinuoEquipo from '../../components/reportes/ceic-continuo-equipo/GraficoErrorAleatorioCEICContinuoEquipo'
import GraficoSigmaCEICContinuoEquipo from '../../components/reportes/ceic-continuo-equipo/GraficoSigmaCEICContinuoEquipo'

const ReporteCEICContinuoEquipo: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: CEICContinuoEquipoDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <div className='table-responsible mb-10'>
          <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
            <thead
              style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}
            >
              <tr className='fw-bolder' style={{verticalAlign: 'middle'}}>
                <th style={{width: `4%`, textAlign: 'center'}}>#</th>
                <th style={{width: `20%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'HEADER.MEASUREMENT_PROCEDURE'})}
                </th>
                <th style={{width: '6%', textAlign: 'center'}}>
                  {intl.formatMessage({id: 'HEADER.LEVEL'})}
                </th>
                <th style={{width: `8%`, textAlign: 'center'}}>Sigma</th>
                <th style={{width: `24%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'MENU.CONTROL_RULES'})}
                </th>
                <th style={{width: `6%`, textAlign: 'center'}}>N</th>
                <th style={{width: `8%`, textAlign: 'center'}}>Ped</th>
                <th style={{width: `8%`, textAlign: 'center'}}>Pfr</th>
                <th style={{width: `8%`, textAlign: 'center'}}>MaxE (Nuf)</th>
                <th style={{width: `8%`, textAlign: 'center', cursor: 'pointer'}}>
                  Tamaño máximo de corrida
                </th>
              </tr>
            </thead>
            <tbody>
              {reporte.ProcedimientosMedida?.length > 0 ? (
                reporte.ProcedimientosMedida.map((procedimiento, index) => {
                  return (
                    <tr
                      key={`reporte_ceic_continuo_equipo_procedimiento_${procedimiento.ProcedimientoMedida.Id}`}
                      style={{textAlign: 'right'}}
                    >
                      <td style={{textAlign: 'center'}}>{index + 1}</td>
                      <td style={{textAlign: 'center'}}>
                        {`${procedimiento.ProcedimientoMedida.Mensurando.Analito} / ${procedimiento.ProcedimientoMedida.Mensurando.Matriz} / ${procedimiento.ProcedimientoMedida.Mensurando.Unidades} | ${procedimiento.ProcedimientoMedida.Metodo.Reactivo} / ${procedimiento.ProcedimientoMedida.Metodo.PrincipioMedida}`}
                      </td>
                      <td style={{textAlign: 'center'}}>{procedimiento.Nivel.Numero}</td>
                      <td>
                        {procedimiento.Nivel.Sigma.Value.toFixed(
                          procedimiento.Nivel.Sigma.Precision
                        )}
                      </td>
                      {procedimiento.EsquemaReglas ? (
                        <Fragment>
                          <td style={{textAlign: 'center'}}>
                            {procedimiento.EsquemaReglas.ReglasControl.map((regla) => {
                              return (
                                <span
                                  key={`reporte_ceic_continuo_equipo_procedimiento_${procedimiento.ProcedimientoMedida.Id}_regla_${regla.Id}`}
                                  className={`badge badge-light-dark m-1`}
                                >
                                  {regla.Name}
                                </span>
                              )
                            })}
                          </td>
                          <td>{procedimiento.EsquemaReglas.N}</td>
                          <td style={{textAlign: 'center'}}>{procedimiento.EsquemaReglas.Ped}</td>
                          <td>{procedimiento.EsquemaReglas.Pfr}</td>
                          <td>{procedimiento.EsquemaReglas.MaxENuf}</td>
                          <td>{procedimiento.EsquemaReglas.TamanioMaxCorrida}</td>
                        </Fragment>
                      ) : (
                        <td colSpan={5} style={{textAlign: 'center'}}>
                          {intl.formatMessage({id: 'NOT_PLANNABLE'})}
                        </td>
                      )}
                    </tr>
                  )
                })
              ) : (
                <tr className='text-center mb-5'>
                  <td colSpan={20}>{intl.formatMessage({id: 'NOT_FOUND.RESULTS'})}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {reporte.ProcedimientosMedida?.length > 0 && (
          <>
            <div className='mb-10'>
              <AlertSuccess>{`% Método Sigma > 3: ${reporte.ProcedimientosMedidaWithSigmaOk.Value.toFixed(
                reporte.ProcedimientosMedidaWithSigmaOk.Precision
              )}%`}</AlertSuccess>
            </div>
            <div className='mb-10'>
              <GraficoSigmaCEICContinuoEquipo reporte={reporte} />
            </div>
            <div className='mb-10'>
              <GraficoErrorAleatorioCEICContinuoEquipo reporte={reporte} />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ReporteCEICContinuoEquipo
