import {FC, Fragment} from 'react'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import {EstimacionesMensualesDTO} from '../../models/DTOs/SCI/reportes/EstimacionesMensualesDTO'

const ReporteTablaEstimacionesMensuales: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: EstimacionesMensualesDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <div className='table-responsible'>
          <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
            <thead
              style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}
            >
              <tr className='fw-bolder'>
                <th style={{width: `8%`, verticalAlign: 'middle', textAlign: 'center'}} rowSpan={2}>
                  {intl.formatMessage({id: 'HEADER.LEVEL'})}
                </th>
                <th style={{textAlign: 'center'}} colSpan={4}>
                  {intl.formatMessage({id: 'HEADER.LAB'})}
                </th>
                <th style={{textAlign: 'center'}} colSpan={3}>
                  {intl.formatMessage({id: 'HEADER.GROUP'})}
                </th>
                <th style={{width: `6%`, verticalAlign: 'middle', textAlign: 'center'}} rowSpan={2}>
                  Sesgo (%)
                </th>
                <th style={{width: `6%`, verticalAlign: 'middle', textAlign: 'center'}} rowSpan={2}>
                  ET (%)
                </th>
                <th style={{width: `6%`, verticalAlign: 'middle', textAlign: 'center'}} rowSpan={2}>
                  ETa (%)
                </th>
                <th style={{width: `6%`, verticalAlign: 'middle', textAlign: 'center'}} rowSpan={2}>
                  ETa cc
                </th>
                <th style={{width: `6%`, verticalAlign: 'middle', textAlign: 'center'}} rowSpan={2}>
                  Sigma
                </th>
                <th style={{width: `6%`, verticalAlign: 'middle', textAlign: 'center'}} rowSpan={2}>
                  ESc
                </th>
              </tr>
              <tr className='fw-bolder'>
                <th style={{width: `8%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'SETTINGS.MONTHLY_MEAN'})}
                </th>
                <th style={{width: `8%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'SETTINGS.MONTHLY_CV'})}
                </th>
                <th style={{width: `8%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'SETTINGS.MONTHLY_N'})}
                </th>
                <th style={{width: `8%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'SETTINGS.ACCUMULATED_CV'})}
                </th>
                <th style={{width: `8%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'SETTINGS.GROUP_MEAN'})}
                </th>
                <th style={{width: `8%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'SETTINGS.GROUP_CV'})}
                </th>
                <th style={{width: `8%`, textAlign: 'center'}}>
                  {intl.formatMessage({id: 'SETTINGS.GROUP_N'})}
                </th>
              </tr>
            </thead>
            <tbody>
              {reporte.Estimaciones.length > 0 ? (
                reporte.Estimaciones.map((estimacion) => {
                  return (
                    <Fragment
                      key={`reporte_estimaciones_mensuales_procedimiento_${estimacion.ProcedimientoMedida.Id}`}
                    >
                      <tr style={{textAlign: 'center', background: '#E4E6EF'}}>
                        <td colSpan={20}>
                          {`${estimacion.ProcedimientoMedida.Equipo.Descripcion} / ${estimacion.ProcedimientoMedida.Equipo.Plataforma} | ${estimacion.ProcedimientoMedida.Mensurando.Analito} / ${estimacion.ProcedimientoMedida.Mensurando.Matriz} / ${estimacion.ProcedimientoMedida.Mensurando.Unidades} | ${estimacion.ProcedimientoMedida.Metodo.Reactivo} / ${estimacion.ProcedimientoMedida.Metodo.PrincipioMedida}`}
                        </td>
                      </tr>
                      {estimacion.Niveles.map((nivel) => {
                        return (
                          <tr
                            key={`reporte_estimaciones_mensuales_procedimiento_${estimacion.ProcedimientoMedida.Id}_nivel_${nivel.Numero}`}
                            style={{textAlign: 'right'}}
                          >
                            <td style={{textAlign: 'center'}}>{nivel.Numero}</td>
                            <td>
                              {nivel.Laboratorio.Media.Value.toFixed(
                                nivel.Laboratorio.Media.Precision
                              )}
                            </td>
                            <td>
                              {nivel.Laboratorio.Cv?.Value.toFixed(nivel.Laboratorio.Cv?.Precision)}
                            </td>
                            <td>{nivel.Laboratorio.N}</td>
                            <td>
                              {nivel.Laboratorio.CvAcumulado.Value.toFixed(
                                nivel.Laboratorio.CvAcumulado.Precision
                              )}
                            </td>
                            <td>{nivel.Grupo.Media.Value.toFixed(nivel.Grupo.Media.Precision)}</td>
                            <td>
                              {nivel.Grupo.CvAcumulado.Value.toFixed(
                                nivel.Grupo.CvAcumulado.Precision
                              )}
                            </td>
                            <td>{nivel.Grupo.N}</td>
                            <td>
                              {nivel.SesgoPercent?.Value.toFixed(nivel.SesgoPercent?.Precision) ??
                                '-'}
                            </td>
                            <td>
                              {nivel.ETPercent?.Value.toFixed(nivel.ETPercent?.Precision) ?? '-'}
                            </td>
                            <td>
                              {nivel.ETaPercent?.Value.toFixed(nivel.ETaPercent?.Precision) ?? '-'}
                            </td>
                            <td>{nivel.ETaCc?.Value.toFixed(nivel.ETaCc?.Precision) ?? '-'}</td>
                            <td>{nivel.Sigma?.Value.toFixed(nivel.Sigma?.Precision) ?? '-'}</td>
                            <td>{nivel.ESc?.Value.toFixed(nivel.ESc?.Precision) ?? '-'}</td>
                          </tr>
                        )
                      })}
                    </Fragment>
                  )
                })
              ) : (
                <tr className='text-center mb-5'>
                  <td colSpan={20}>{intl.formatMessage({id: 'NOT_FOUND.RESULTS'})}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default ReporteTablaEstimacionesMensuales
