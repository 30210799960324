import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import {RolUsuario} from '../../../../app/models/DTOs/common/types'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <AsideMenuItem
        id='pagPrincipal'
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {user.Rol.Name === RolUsuario.AdministradorLaboratorio && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'HEADER.MONTHLY_DATA'})}
              </span>
            </div>
          </div>

          <AsideMenuItem
            id='datosMensuales'
            icon='/media/icons/duotune/text/txt001.svg'
            title={intl.formatMessage({id: 'SETTINGS.ADMIN'})}
            fontIcon='bi-app-indicator'
            to='/monthly-data'
          />

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.REPORT'})}
              </span>
            </div>
          </div>

          <AsideMenuItem
            id='estimacionesMensuales'
            icon='/media/icons/duotune/graphs/gra001.svg'
            title={intl.formatMessage({id: 'HEADER.MONTHLY_ESTIMATES'})}
            fontIcon='bi-app-indicator'
            to='/reports/monthly-estimates'
          />
          <AsideMenuItem
            id='UProcedimientoMedida'
            icon='/media/icons/duotune/graphs/gra001.svg'
            title={intl.formatMessage({id: 'HEADER.U_MEASUREMENT_PROCEDURE'})}
            fontIcon='bi-app-indicator'
            to='/reports/u-measurement-procedure'
          />
          <AsideMenuItem
            id='UEquipo'
            icon='/media/icons/duotune/graphs/gra001.svg'
            title={intl.formatMessage({id: 'HEADER.U_EQUIPMENT'})}
            fontIcon='bi-app-indicator'
            to='/reports/u-equipment'
          />
          <AsideMenuItem
            id='CEICTradicionalProcedimientoMedida'
            icon='/media/icons/duotune/graphs/gra001.svg'
            title={intl.formatMessage({id: 'HEADER.CEIC_TRADITIONAL_MEASUREMENT_PROCEDURE'})}
            fontIcon='bi-app-indicator'
            to='/reports/ceic-traditional-measurement-procedure'
          />
          <AsideMenuItem
            id='CEICTradicionalEquipo'
            icon='/media/icons/duotune/graphs/gra001.svg'
            title={intl.formatMessage({id: 'HEADER.CEIC_TRADITIONAL_EQUIPMENT'})}
            fontIcon='bi-app-indicator'
            to='/reports/ceic-traditional-equipment'
          />
          <AsideMenuItem
            id='CEICContinuoProcedimientoMedida'
            icon='/media/icons/duotune/graphs/gra001.svg'
            title={intl.formatMessage({id: 'HEADER.CEIC_CONTINUOUS_MEASUREMENT_PROCEDURE'})}
            fontIcon='bi-app-indicator'
            to='/reports/ceic-continuous-measurement-procedure'
          />
          <AsideMenuItem
            id='CEICContinuoEquipo'
            icon='/media/icons/duotune/graphs/gra001.svg'
            title={intl.formatMessage({id: 'HEADER.CEIC_CONTINUOUS_EQUIPMENT'})}
            fontIcon='bi-app-indicator'
            to='/reports/ceic-continuous-equipment'
          />
        </>
      )}
    </>
  )
}
